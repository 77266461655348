import React, { useCallback, useContext, useEffect, useState } from "react";

import { InputDropDowList2, InputSelector, InputText, PageControl, TabSheet } from "../../../../components";

import { Grid, Button } from "@material-ui/core";
import { AppContext } from "../../../../App";

import { AppOptionsContext } from "../../../../components/main/main";
import { TypeIssuerItem, TypeStocksKeeper, TypeStocksKeeperDefault } from "../utils";
import { ValidateOperationsNature } from "../validates";
import ApiOperationsNature from "../../../../api/basics/operationsNature";
import QueryOrdersTypes from "../../query/ordersTypes";
import { useMemo } from "react";
import OperationsNatureReferencesChange from "../referencesChange";

const initialValues = {
    id: "",
    name: "",
    stock_keeper: TypeStocksKeeperDefault,
    type_stock: "",
    control_third_stock: false,
    type_issuer_item: "",
    order_type_id: 0,
    order_type: {},
    attributes: {},
};

export default function OperationsNatureChange({ id, onLoadRecord, onFinalize, showOptions, types }) {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);

    const [tabIndex, setTabIndex] = useState(0);

    const [operationsNature, setOperationsNature] = useState(initialValues);

    const [operationsNatureReferences, setOperationsNatureReferences] = useState([]);

    useEffect(() => {
        loadRecord(id);
    }, [id]);

    const typeStock = useMemo(() => {
        return (
            types
                .find((rec) => rec.entity === "operations_nature" && rec.field === "type_stock")
                ?.values?.map((rec) => {
                    return {
                        value: rec.value,
                        text: `${rec.name}`,
                    };
                }) || []
        );
    }, [types]);

    const typeStockDefault = useMemo(() => {
        return typeStock[0]?.value;
    }, [typeStock]);

    const loadRecord = async (id) => {
        if (!id || id === 0) return;

        setLoading(true);

        try {
            const result = await ApiOperationsNature().apiDefault.findId(id, { associates: ["order_type", "references", "references.reference"] });

            if (!result.status) {
                showMessageError(result.message);
                return;
            }

            setOperationsNature(result.data);
            setOperationsNatureReferences(result.data.references || []);

            onLoadRecord();
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onClearRecord = () => {
        setOperationsNature(initialValues);
        setOperationsNatureReferences([]);

        if (id) {
            onFinalize(false);
        }
    };

    const onSave = useCallback(
        async (operationsNature, operationsNatureReferences) => {
            const operationsNatureApply = {
                id: operationsNature.id,
                name: operationsNature.name,
                stock_keeper: operationsNature.stock_keeper,
                type_stock: operationsNature.type_stock || null,
                type_issuer_item: operationsNature.type_issuer_item,
                control_third_stock: operationsNature.control_third_stock || false,
                order_type_id: operationsNature.order_type.id,
                is_references: false,
                operation_type: operationsNature.order_type.type_operation,
                references: [],
                attributes: {},
            };

            operationsNatureApply.references = (operationsNatureReferences || []).map((rec) => {
                return {
                    operation_nature_reference_id: rec.operation_nature_reference_id,
                };
            });

            if (operationsNatureApply.references.length) {
                operationsNatureApply.is_references = true;
            }

            // Validate
            const typeStockValidade = typeStock.map((rec) => rec.value);

            const validate = await ApiOperationsNature().apiDefault.validate(ValidateOperationsNature(typeStockValidade), operationsNatureApply);
            if (!validate.status) {
                showMessageError(validate.message);
                return;
            }

            setLoading(true);
            try {
                let response = {};

                if (id) {
                    delete operationsNatureApply.id;

                    response = await ApiOperationsNature().apiDefault.update(id, operationsNatureApply);
                } else {
                    response = await ApiOperationsNature().apiDefault.creates(operationsNatureApply);
                }

                if (!response.status) {
                    showMessageError(response.message);
                    return;
                }

                showMessageInfo("Registro atualizado com sucesso");

                onClearRecord();
                onFinalize(true);
            } finally {
                setLoading(false);
            }
        },
        [id, typeStock]
    );

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClearRecord()}
                    >
                        {id ? "Cancelar" : "Limpar tela"}
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(operationsNature, operationsNatureReferences)}
                    >
                        Gravar
                    </Button>
                </>
            );
        }
    }, [showOptions, operationsNature, operationsNatureReferences, id]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={1}>
                    <InputText
                        required
                        label="Código"
                        value={operationsNature.id}
                        onChangeValue={(e) => setOperationsNature({ ...operationsNature, id: String(e.target.value).toUpperCase().substring(0, 10) })}
                        disabled={!!id}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <QueryOrdersTypes
                        title="Consulta Tipos de Ordens"
                        label="Tipo de Ordem"
                        value={operationsNature.order_type}
                        onChangeValue={(data) => setOperationsNature({ ...operationsNature, order_type: data })}
                    />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <InputText
                        required
                        label="Nome"
                        value={operationsNature.name}
                        onChangeValue={(e) => setOperationsNature({ ...operationsNature, name: e.target.value })}
                    />
                </Grid>
            </Grid>
            <PageControl style={{ marginTop: "10px" }} tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Estoque">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <InputSelector
                                label={"Natureza de Operação não controla estoque"}
                                value={operationsNature.stock_keeper === null}
                                onChangeValue={(e) =>
                                    setOperationsNature({
                                        ...operationsNature,
                                        stock_keeper: e.target.checked ? null : TypeStocksKeeperDefault,
                                        type_stock: e.target.checked ? null : typeStockDefault,
                                        control_third_stock: e.target.checked ? false : operationsNature.control_third_stock,
                                    })
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3}>
                            <InputDropDowList2
                                value={operationsNature.stock_keeper}
                                onChangeValue={(e) => setOperationsNature({ ...operationsNature, stock_keeper: e.target.value })}
                                data={TypeStocksKeeper}
                                label={"Posse/Propriedade"}
                                disabled={operationsNature.stock_keeper === null}
                            ></InputDropDowList2>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputDropDowList2
                                value={operationsNature.type_stock}
                                onChangeValue={(e) => setOperationsNature({ ...operationsNature, type_stock: e.target.value })}
                                data={typeStock}
                                label={"Tipo Estoque"}
                                disabled={operationsNature.stock_keeper === null}
                            ></InputDropDowList2>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputDropDowList2
                                value={operationsNature.type_issuer_item}
                                onChangeValue={(e) => setOperationsNature({ ...operationsNature, type_issuer_item: e.target.value })}
                                data={TypeIssuerItem}
                                label={"Regra de Item"}
                            ></InputDropDowList2>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <InputSelector
                                label={"Controla estoque em terceira pessoa em Remessas"}
                                value={operationsNature.control_third_stock || false}
                                onChangeValue={(e) => setOperationsNature({ ...operationsNature, control_third_stock: e.target.checked })}
                                disabled={operationsNature.stock_keeper === null}
                            />
                        </Grid>
                    </Grid>
                </TabSheet>
                <TabSheet label="Referencias">
                    <OperationsNatureReferencesChange references={operationsNatureReferences} setReferences={setOperationsNatureReferences} />
                </TabSheet>
            </PageControl>
        </>
    );
}
