import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";

import { InputDate, GradeOpcoes, InputText, PageControl, TabSheet, InputDecimal, Grade } from "../../../../../components";
import { Button, Grid } from "@material-ui/core";
import { AppContext } from "../../../../../App";
import { AppOptionsContext } from "../../../../../components/main/main";
import ApiMovementsItems from "../../../../../api/stocks/movementsItems ";
import InputLookupMembers from "../../../../basics/lookup/members";
import InputLookupItems from "../../../../basics/lookup/items";
import InputLookupOperationsNature from "../../../../basics/lookup/operationsNature";
import ApiBalancesItems from "../../../../../api/stocks/balancesItems";
import FormatsUtils from "../../../../../services/FormatsUtils";
import { ManualMovementsValidate } from "../validates";
import { TYPE_STOCK_HOLDER } from "../utils";

const MOVEMENT_ITEMS_INITIAL = {
    branch: {},
    operation_nature: {},
    balance_date: new Date(),
    hold_member: {},
    justify: "",
};

const ITEM_INITIAL = {
    item_id: 0,
    item: {},
    amount: 0,
    amount_balance: 0,
    cost_value: 0,
    total: 0,
};

export default function ManualMovementsItems({ showOptions, itemSelected }) {
    const { setLoading, showMessageError, showMessageInfo, showMessageConfirmation } = useContext(AppContext);
    const { setOptions } = useContext(AppOptionsContext);
    const [tabIndex, setTabIndex] = useState(0);

    const [movementItems, setMovementItems] = useState(MOVEMENT_ITEMS_INITIAL);
    const [item, setItem] = useState(ITEM_INITIAL);
    const [items, setItems] = useState([]);

    const metaDataItems = useMemo(() => [
        {
            label: "Opções",
            align: "center",
            render: (record) => {
                const options = [{ tipo: "remove", label: "Excluir item" }];

                return (
                    <span>
                        <GradeOpcoes record={record} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        { label: "Item", field: "item_id" },
        {
            label: "Nome do item",
            render: (record) => {
                return <>{record.item?.name}</>;
            },
        },
        {
            label: "Qtd.",
            field: "amount",
            format: "float",
            decimal: 3,
            align: "right",
        },
        {
            label: "Custo",
            field: "cost_value",
            format: "float",
            decimal: 2,
            align: "right",
        },
        {
            label: "Total",
            field: "total",
            format: "float",
            decimal: 2,
            align: "right",
        },
    ]);

    useEffect(() => {
        return () => {
            setOptions(null);
        };
    }, []);

    useEffect(() => {
        if (itemSelected?.id) {
        }
    }, [itemSelected]);

    const onClickOptions = (record, type, index) => {
        switch (type) {
            case "edit":
                setItem({ ...record });
                break;
            case "remove":
                const itemsCustom = [...items];
                itemsCustom.splice(index, 1);
                setItems(itemsCustom);
                break;
            default:
                break;
        }
    };

    const onClear = () => {
        setMovementItems(MOVEMENT_ITEMS_INITIAL);
        setItems([]);
        setItem(ITEM_INITIAL);
    };

    const onSave = useCallback(async (movementItems, items) => {
        const movementItemsApply = items.map((rec) => {
            return {
                branch_id: movementItems.branch?.id || 0,
                hold_member_id: movementItems.hold_member?.id || null,
                item_id: rec.item_id,
                operation_nature_id: movementItems.operation_nature?.id || 0,
                stock_keeper: movementItems.operation_nature?.stock_keeper || "",
                status: "pending",
                type_stock: movementItems.operation_nature?.type_stock || "",
                type_operation: movementItems.operation_nature?.operation_type || "",
                movement_date: FormatsUtils().dateAnsi(movementItems.balance_date),
                amount: rec.amount,
                cost_value: rec.cost_value,
                justify: movementItems.justify,
            };
        });

        // Validate
        const validate = await ApiMovementsItems().apiDefault.validate(ManualMovementsValidate(), movementItemsApply);
        if (!validate.status) {
            showMessageError(validate.message);
            return;
        }

        // Validate holder
        if (TYPE_STOCK_HOLDER.includes(movementItems.operation_nature?.stock_keeper)) {
            if (!movementItems.hold_member?.id) {
                showMessageError(`Tipo de natureza de operação, exige membro Posse/Proprietário.`);
                return;
            }
        } else {
            if (movementItems.hold_member?.id) {
                showMessageError(`Tipo de natureza de operação não deve ser informado membro Posse/Proprietário.`);
                return;
            }
        }

        setLoading(true);
        try {
            const response = await ApiMovementsItems().apiDefault.creates(movementItemsApply);
            if (!response.status) {
                showMessageError(response.message);
                return;
            }

            showMessageInfo("Lançamentos de movimentação efetuados!");
            onClear();
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (showOptions) {
            setOptions(
                <>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClear()}
                    >
                        Limpar tela
                    </Button>
                    <Button
                        style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={!items.length}
                        onClick={() => onSave(movementItems, items)}
                    >
                        Gravar Movimentação
                    </Button>
                </>
            );
        }
    }, [showOptions, movementItems, items]);

    const onLoadBalance = useCallback(async () => {
        // Not Apply
        if (!item.item?.id || !movementItems.balance_date || !movementItems.branch?.id || !movementItems.operation_nature?.id) {
            return;
        }

        // Find Balance Item
        setLoading(true);
        try {
            const response = await ApiBalancesItems().balances(item.item.id, movementItems.branch?.id, FormatsUtils().dateAnsi(movementItems.balance_date), {
                holdMemberId: movementItems.hold_member?.id,
                stockKeeper: movementItems.operation_nature?.stock_keeper,
                stockType: movementItems.operation_nature?.type_stock,
            });

            if (!response.status && response.message?.length) {
                showMessageError(response.message);
                return;
            }

            if (response.status) {
                const balanceItemFirst = response.data.shift();
                setItem({ ...item, amount_balance: balanceItemFirst.amount, cost_value: balanceItemFirst.cost_value });
            }
        } finally {
            setLoading(false);
        }
    }, [item, movementItems]);

    useEffect(() => {
        onLoadBalance();
    }, [item.item?.id, movementItems.hold_member, movementItems.operation_nature, movementItems.branch]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <InputLookupMembers
                        roleMember="branch"
                        label="Estabelecimento"
                        value={movementItems.branch}
                        onChangeValue={(data) => setMovementItems({ ...movementItems, branch: data })}
                        required={true}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLookupOperationsNature
                        label="Natureza de Operação"
                        value={movementItems.operation_nature}
                        onChangeValue={(data) => setMovementItems({ ...movementItems, operation_nature: data })}
                        required={true}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputDate
                        label="Data movimento"
                        value={movementItems.balance_date}
                        onChangeValue={(date) => setMovementItems({ ...movementItems, balance_date: date })}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputText
                        label="Justificativa"
                        value={movementItems.justify}
                        onChangeValue={(e) => setMovementItems({ ...movementItems, justify: e.target.value })}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLookupMembers
                        roleMember="customer,supplier"
                        label="Posse/propriedade"
                        value={movementItems.hold_member}
                        onChangeValue={(data) => setMovementItems({ ...movementItems, hold_member: data })}
                        required={TYPE_STOCK_HOLDER.includes(movementItems.operation_nature?.stock_keeper)}
                    />
                </Grid>
            </Grid>
            <PageControl style={{ marginTop: "10px" }} tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Itens">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <InputLookupItems
                                label={"Item"}
                                typeItem="goods"
                                value={item.item}
                                disabled={!movementItems.operation_nature?.id}
                                onChangeValue={(data) => setItem({ ...item, item: data })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputDecimal
                                label={`Saldo em ${FormatsUtils().date(movementItems.balance_date)}`}
                                value={item.amount_balance}
                                symbolEnds={item?.item?.id ? `${item?.item.unit_inventory_id}${item?.item.amount_unit_inventory}` : "UN"}
                                decimal={3}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                        <Grid item xs={12} sm={2}>
                            <InputDecimal
                                label={"Quantidade"}
                                value={item.amount}
                                symbolEnds={item?.item?.id ? `${item?.item.unit_inventory_id}${item?.item.amount_unit_inventory}` : "UN"}
                                decimal={3}
                                onChangeValue={(e) =>
                                    setItem({
                                        ...item,
                                        amount: e.target.valueNumber,
                                        total: e.target.valueNumber * item.cost_value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDecimal
                                label={"Valor unitário"}
                                value={item.cost_value}
                                symbol="R$"
                                decimal={2}
                                onChangeValue={(e) =>
                                    setItem({
                                        ...item,
                                        cost_value: e.target.valueNumber,
                                        total: e.target.valueNumber * item.amount,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDecimal label={"Total do item"} value={item.total} symbol="R$" decimal={2} disabled={true} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputDecimal
                                label={"Saldo Final"}
                                value={
                                    (item.amount_balance || 0) +
                                    (movementItems.operation_nature?.operation_type === "in" ? item.amount || 0 : -1 * (item.amount || 0))
                                }
                                symbolEnds={item?.item?.id ? `${item?.item.unit_inventory_id}${item?.item.amount_unit_inventory}` : "UN"}
                                decimal={3}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                        <Grid item xs={12} sm={12}>
                            <Button
                                style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() => setItem(ITEM_INITIAL)}
                            >
                                Novo item
                            </Button>
                            <Button
                                style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    const indexItem = items.findIndex((rec) => rec.item_id === item.item.id);
                                    const itemsCustom = [...items];

                                    // Populate default id's
                                    item.item_id = item.item.id;
                                    item.amount = movementItems.operation_nature?.operation_type === "in" ? item.amount : -1 * item.amount;

                                    if (indexItem === -1) {
                                        itemsCustom.push(item);
                                    } else {
                                        itemsCustom[indexItem] = item;
                                    }

                                    setItems(itemsCustom);
                                    setItem(ITEM_INITIAL);
                                }}
                            >
                                Salvar item
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grade dataSource={items} metaData={metaDataItems} disablepagination={true} maxHeight={300} />
                    </Grid>
                </TabSheet>
            </PageControl>
        </>
    );
}
