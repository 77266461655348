import * as yup from "yup";

export function ManualMovementsValidate() {
    const movementItemsValidate = yup.object().shape({
        branch_id: yup.number().required("Estabeleciento não informado."),
        item_id: yup.number().required("Item não informado."),
        operation_nature_id: yup.string().required("Natureza de Operação não informada."),
        movement_date: yup.date().required("Data de movimentação não informada."),
        amount: yup.number().required("Quantidade do item não informada."),
        cost_value: yup.number().required("Preço do item não informada.").moreThan(0, "Preço deve ser maior que zero."),
        justify: yup.string().required("Justificativa não informada."),
    });

    return yup.array().required("Itens não informados").min(1, "Nenhum item informado").of(movementItemsValidate);
}
