import React, { useState, useEffect, useContext, useMemo } from "react";

import { getInicioMes, getFinalMes, getDecMes } from "../../../../../services/date.services";

import QueryMembers from "../../../../basics/query/members";
import FormatsUtils from "../../../../../services/FormatsUtils";
import ApiDocuments from "../../../../../api/fiscal/documents/ApiDocumentsService";

import { Grade, PageControl, TabSheet, GradeOpcoes, InputDate, InputText, InputDropDowList, InputDropDowList2 } from "../../../../../components";

import { Button, MenuItem, Grid } from "@material-ui/core";
import { AppContext } from "../../../../../App";

import SearchIcon from "@material-ui/icons/Search";
import BackupIcon from "@material-ui/icons/Backup";
import UpdateIcon from "@material-ui/icons/Update";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

import DocumentsServiceDetails from "../documentsDetails";

import ApiTypes from "../../../../../api/system/types/ApiTypes";
import DocumentsChange from "../documentsChange";

import { AppOptionsContext } from "../../../../../components/main/main";
import DocumentsImport from "../documentsImport";
import ApiDocumentsTypes from "../../../../../api/basics/documentsTypes";
import ApiLayoutsFiles from "../../../../../api/system/layoutsFiles/ApiLayoutsFiles";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import TablePagination from "../../../../../components/grade/TablePagination";

const initialCondition = {
    issuer: {},
    type_document: "-",
    status: "-",
    number_in: "",
    date_due_less_equal: getFinalMes(getDecMes()),
    date_due_more_equal: getInicioMes(getDecMes()),
};

export default function DocumentsServiceOutbound() {
    const { setLoading, showMessageError, showMessageInfo } = useContext(AppContext);
    const { setOptions, setTitle } = useContext(AppOptionsContext);

    const [openImport, setOpenImport] = useState(false);

    const [idDetails, setIdDetails] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);

    const [idInEdition, setIdInEdition] = useState(0);
    const [layoutsFiles, setLayoutsFiles] = useState([]);

    const [tabIndex, setTabIndex] = useState(0);
    const [dataSource, setDataSource] = useState([]);

    const [rowCount, setRowCount] = useState(0);
    const [rowPage, setRowPage] = useState(10);
    const [page, setPage] = useState(0);

    const [condition, setCondition] = useState(initialCondition);
    const [conditionApply, setConditionApply] = useState(initialCondition);

    const [typesDocument, setTypesDocument] = useState([]);
    const [documentsTypes, setDocumentsTypes] = useState([]);

    const metaData = useMemo(() => [
        {
            label: "Opções",
            render: (record) => {
                const options = [
                    { tipo: "details", label: "Detalhes", disabled: !record.amount_items && !record.amount_taxes && !record.amount_invoices },
                    { tipo: "edit", label: "Editar" },
                ];

                // Update
                if (record.status === "pending") {
                    options.push({
                        tipo: "update_itens_taxes",
                        label: "Atualizar Documento",
                    });
                }

                return (
                    <span>
                        <GradeOpcoes record={record} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        {
            label: "Prestador",
            render: (record) => {
                return (
                    <>
                        {record.issuer_name}
                        <br />
                        Documento: {FormatsUtils().document(record.issuer_document, record.issuer_type)}
                    </>
                );
            },
        },

        { label: "Numero", field: "number" },
        { label: "Serie", field: "serial" },
        { label: "Data Emissão", field: "date_due", format: "date" },
        {
            label: "Valor Serviços",
            field: "total_items",
            format: "float",
            decimal: 2,
            align: "right",
        },
        {
            label: "Valor Líquido",
            field: "total",
            format: "float",
            decimal: 2,
            align: "right",
        },
        {
            label: "Situação",
            render: (record) => {
                let situacao = "Pendente";
                switch (record.status) {
                    case "active":
                        situacao = "Ativa";
                        break;
                    case "cancelled":
                        situacao = "Cancelada";
                        break;
                    default:
                        break;
                }

                return <span> {situacao} </span>;
            },
        },
        {
            label: "Tomador",
            render: (record) => {
                return (
                    <>
                        {record.receiver_name}
                        <br />
                        Documento: {FormatsUtils().document(record.receiver_document, record.receiver_type)}
                    </>
                );
            },
        },
    ]);

    useEffect(() => {
        setTitle("Documentos Fiscais Prestados (Serviços)");
        loadDataSource(0);

        // Types
        ApiTypes()
            .apiDefault.findAll({ entity_in: "documents,documents_items,documents_files,documents_taxes" })
            .then((rec) => {
                setTypesDocument(rec.data);
            });

        // Layouts Import and Export
        ApiLayoutsFiles()
            .apiDefault.findAll({ internal_layout_in: "import-documents-service-out" })
            .then((rec) => {
                setLayoutsFiles(rec.data);
            });

        // Types Documents
        ApiDocumentsTypes()
            .apiDefault.findAll({ type_nature: "services" })
            .then((rec) => {
                setDocumentsTypes(rec.data);
            });

        return () => {
            setOptions(null);
        };
    }, []);

    const documentsTypesFilter = useMemo(() => {
        const result = documentsTypes.map((rec) => {
            return {
                value: rec.id,
                text: rec.name,
            };
        });

        result.unshift({
            text: "Todos",
            value: "-",
        });

        return result;
    }, [documentsTypes]);

    const loadDataSource = async (newPage, newRowPage, newCondition) => {
        setLoading(true);

        // Condition
        const conditionCustom = makeCondition(newCondition || conditionApply);

        try {
            const result = await ApiDocuments().managerServiceList({ page: newPage + 1, pageSize: newRowPage || rowPage, condition: conditionCustom });

            if (result.status) {
                setDataSource(result.data);
                setRowCount(result.info.total);
            } else {
                setDataSource([]);
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError({ active: true, message: e.message });
        } finally {
            setLoading(false);
        }
    };

    const makeCondition = (newCondition, ignoreStatus) => {
        const result = {};

        // Branch
        if (newCondition.issuer?.id) {
            result.issuer_id = newCondition.issuer?.id;
        }

        // Date Due
        if (newCondition.date_due_less_equal) {
            result.date_due_less_equal = FormatsUtils().dateAnsi(newCondition.date_due_less_equal);
        }
        if (newCondition.date_due_more_equal) {
            result.date_due_more_equal = FormatsUtils().dateAnsi(newCondition.date_due_more_equal);
        }

        // Number
        if (newCondition.number_in) {
            result.number_in = newCondition.number_in
                .split(",")
                .map((rec) => Number(rec))
                .join(",");
        }

        // Status
        if (!ignoreStatus && newCondition.status !== "-") {
            result.status = newCondition.status;
        }

        // Type Document
        if (newCondition.type_document !== "-") {
            result.type_document = newCondition.type_document;
        }

        return result;
    };

    const onClickOptions = (record, tipo) => {
        switch (tipo) {
            case "edit":
                setIdInEdition(record.id);
                break;
            case "details":
                setIdDetails(record.id);
                setOpenDetails(true);
                break;
            case "update_itens_taxes":
                onUpdateDocument(record.id);
                break;
            default:
                break;
        }
    };

    const onExportDocuments = async () => {
        const condition = makeCondition(conditionApply, true);

        setLoading(true);

        try {
            const result = await ApiDocuments().exportDocuments(condition, "dominio", "dominio-" + FormatsUtils().dateTimeUnique(new Date()));

            if (!result.status) {
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onUpdateDocument = async (id) => {
        setLoading(true);

        try {
            const result = await ApiDocuments().defineItemsAndTaxes({ id });

            if (result.status) {
                showMessageInfo(
                    <>
                        <strong>Registro {id} atualizado</strong>
                        <br />
                        <li>Registros atualizados: {result.data.updated}</li>
                        <li>Registros inconscistentes: {result.data.pending}</li>
                        <li>Mensagens:</li>
                        {result.message.map((msg) => {
                            return (
                                <>
                                    -{msg}
                                    <br />
                                </>
                            );
                        })}
                    </>
                );
            } else {
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onUpdateDocuments = async () => {
        const condition = makeCondition(conditionApply, true);

        setLoading(true);

        try {
            const result = await ApiDocuments().defineItemsAndTaxes(condition);

            if (result.status) {
                showMessageInfo(
                    <>
                        <strong>Atualização efetuada com sucesso</strong>
                        <br />
                        <li>Total registros: {result.data.count}</li>
                        <li>Registros atualizados: {result.data.updated}</li>
                        <li>Registros inconscistentes: {result.data.pending}</li>
                        <li>Mensagens:</li>
                        {result.message.map((msg) => {
                            return (
                                <>
                                    -{msg}
                                    <br />
                                </>
                            );
                        })}
                    </>
                );
            } else {
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onFinalize = async (updated) => {
        setTabIndex(0);
        setIdInEdition(0);

        if (updated) {
            loadDataSource(page);
        }
    };

    useEffect(() => {
        if (tabIndex === 0) {
            setOptions(
                <>
                    <TablePagination
                        rowCount={rowCount}
                        rowPage={rowPage}
                        page={page}
                        onPageChange={(event, newPage) => {
                            loadDataSource(newPage);
                            setPage(newPage);
                        }}
                        onRowsPerPageChange={(event) => {
                            loadDataSource(0, event.target.value);
                            setPage(0);
                            setRowPage(event.target.value);
                        }}
                    />
                </>
            );
        }
    }, [tabIndex, rowCount, rowPage, page]);

    const onExecuteImport = async (files, layout, documentTypeDefault) => {
        setLoading(true);

        try {
            const result = await ApiDocuments().receiptService(files, layout, documentTypeDefault);

            if (result.status) {
                showMessageInfo(
                    <>
                        <strong>Importação efetuada com sucesso</strong>
                        <br />
                        <li>Total registros: {result.data.count}</li>
                        <li>Destinatários Criados: {result.data.customers_create}</li>
                        <li>Documentos Criados: {result.data.documents_create}</li>
                        <li>Mensagens:</li>
                        {result.message.map((msg) => {
                            return (
                                <>
                                    -{msg}
                                    <br />
                                </>
                            );
                        })}
                    </>
                );
            } else {
                if (result.message) {
                    showMessageError(result.message);
                }
            }
        } catch (e) {
            showMessageError(e.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageControl tabindex={tabIndex} onchangetab={(index) => setTabIndex(index)}>
                <TabSheet label="Serviços Prestados">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <InputDropDowList2
                                value={condition.type_document}
                                onChangeValue={(e) => setCondition({ ...condition, type_document: e.target.value })}
                                data={documentsTypesFilter}
                                label={"Tipo Documento"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <QueryMembers
                                title="Consulta Estabelecimentos"
                                roleMember="branch"
                                label="Estabelecimento"
                                value={condition.issuer}
                                onChangeValue={(data) => setCondition({ ...condition, issuer: data })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDate
                                label="Data inicial"
                                value={condition.date_due_more_equal}
                                onChangeValue={(date) => setCondition({ ...condition, date_due_more_equal: date })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDate
                                label="Data final"
                                value={condition.date_due_less_equal}
                                onChangeValue={(date) => setCondition({ ...condition, date_due_less_equal: date })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputText
                                label="Número NF"
                                value={condition.number_in}
                                onChangeValue={(e) => setCondition({ ...condition, number_in: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputDropDowList
                                defaultValue={0}
                                label="Situação"
                                value={condition.status}
                                onChangeValue={(e) => setCondition({ ...condition, status: e.target.value })}
                            >
                                <MenuItem value={"-"}>Todos</MenuItem>
                                <MenuItem value={"pending"}>Pendentes</MenuItem>
                                <MenuItem value={"active"}>Ativos</MenuItem>
                                <MenuItem value={"cancelled"}>Cancelados</MenuItem>
                            </InputDropDowList>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => setOpenImport(true)}
                            startIcon={<BackupIcon fontSize="inherit" />}
                        >
                            Importar Arquivo(s)
                        </Button>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={() => {
                                onUpdateDocuments();
                            }}
                            startIcon={<UpdateIcon fontSize="inherit" />}
                        >
                            Atualizar Documentos
                        </Button>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            variant="contained"
                            size="small"
                            type="primary"
                            disabled={!conditionApply.issuer?.id}
                            onClick={() => {
                                onExportDocuments();
                            }}
                            startIcon={<CloudDownloadIcon fontSize="inherit" />}
                        >
                            Exportar Arquivo
                        </Button>

                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            variant="contained"
                            size="small"
                            type="primary"
                            onClick={() => {
                                loadDataSource(0, rowPage, condition);
                                setPage(0);
                                setConditionApply(condition);
                            }}
                            startIcon={<SearchIcon fontSize="inherit" />}
                        >
                            Pesquisar
                        </Button>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            variant="contained"
                            size="small"
                            type="primary"
                            onClick={() => {
                                setCondition(initialCondition);
                            }}
                            startIcon={<DeleteSweepIcon fontSize="inherit" />}
                        >
                            Limpar
                        </Button>
                    </Grid>
                    <Grade dataSource={dataSource} metaData={metaData} disablepagination={true} />
                </TabSheet>
                <TabSheet label={idInEdition ? `Documento ${idInEdition}` : null} icon={idInEdition ? null : <AddCircleIcon />}>
                    <Grid container spacing={1}>
                        <DocumentsChange
                            id={idInEdition}
                            documentsTypes={documentsTypes}
                            typeOperationChange={"out"}
                            typeItemChange={"service"}
                            onLoadRecord={() => setTabIndex(1)}
                            onFinalize={(updated) => onFinalize(updated)}
                            types={typesDocument}
                            showOptions={tabIndex === 1}
                        />
                    </Grid>
                </TabSheet>
            </PageControl>
            <DocumentsServiceDetails id={idDetails} open={openDetails} setOpen={setOpenDetails} setLoading={setLoading} />
            <DocumentsImport
                layouts={layoutsFiles}
                open={openImport}
                setOpen={setOpenImport}
                documentsTypes={documentsTypes}
                title={"Importação Arquivo(s) de Prestação Fiscal de Serviços"}
                onExecute={onExecuteImport}
            />
        </>
    );
}
