import React, { useContext, useEffect, useMemo, useState } from "react";

import { Grid } from "@material-ui/core";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { AppContext } from "../../../../App";
import InputLookupMembers from "../../../basics/lookup/members";

/*
    label: string,
    type: "member-supplier",
    value: null,
*/

export default function AdditionalData({ value, onChangeValue }) {
    const onChangeFieldValue = (field, valueField) => {
        const valueCustom = { ...value[field] };
        valueCustom.value = valueField;

        onChangeValue({ ...value, [field]: valueCustom });
    };

    const metaData = useMemo(() => {
        const attributesNode = [];

        Object.keys(value).forEach((rec) => {
            let attr = undefined;

            switch (rec) {
                case "member-supplier":
                    attr = (
                        <InputLookupMembers
                            roleMember="supplier"
                            label={value[rec].label}
                            value={value[rec].value}
                            required
                            onChangeValue={(data) => {
                                const { id, name, document, type_member, city_name, state_id } = data;
                                onChangeFieldValue(rec, { id, name, document, type_member, city_name, state_id });
                            }}
                        />
                    );
                    break;
                case "member-customer":
                    attr = (
                        <InputLookupMembers
                            roleMember="customer"
                            label={value[rec].label}
                            value={value[rec].value}
                            required
                            onChangeValue={(data) => {
                                const { id, name, document, type_member, city_name, state_id } = data;
                                onChangeFieldValue(rec, { id, name, document, type_member, city_name, state_id });
                            }}
                        />
                    );
                    break;
                case "member-receiver":
                    attr = (
                        <InputLookupMembers
                            roleMember="supplier,customer"
                            label={value[rec].label}
                            value={value[rec].value}
                            required
                            onChangeValue={(data) => {
                                const { id, name, document, type_member, city_name, state_id } = data;
                                onChangeFieldValue(rec, { id, name, document, type_member, city_name, state_id });
                            }}
                        />
                    );
                    break;
                default:
                    break;
            }

            attributesNode.push(
                <>
                    <Grid item xs={12} sm={12}>
                        {attr}
                    </Grid>
                </>
            );
        });

        return attributesNode;
    }, [value]);

    return (
        <Grid container spacing={1}>
            {metaData.map((rec) => rec)}
        </Grid>
    );
}

export function AdditionalDataModal({ id, value, onConfirmation, open, setOpen }) {
    const { showMessageInfo } = useContext(AppContext);

    const [valueAttr, setValueAttr] = useState(value);

    useEffect(() => {
        setValueAttr(value);
    }, [value]);

    useEffect(() => {
        if (open) {
            setValueAttr(value);

            if (!Object.keys(value).length) {
                showMessageInfo("Não existem dados para serem listados.");
                setOpen(false);
            }
        }
    }, [open]);

    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            open={open}
            onClose={(e, reason) => {
                if (!["backdropClick", "escapeKeyDown"].includes(reason)) {
                    setOpen(false);
                }
            }}
        >
            <DialogTitle>Atributos Registro {id}</DialogTitle>
            <DialogContent>
                <AdditionalData onChangeValue={setValueAttr} value={valueAttr} />
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ float: "right", marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                onConfirmation(id, valueAttr);
                                setOpen(false);
                            }}
                        >
                            Gravar e Processar arquivo
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
