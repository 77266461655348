import fileDownload from "js-file-download";
import ApiDefault from "../../ApiDefault";

export default function ApiBalancesItems() {
    const apiDefault = ApiDefault("v2/stocks/balances-items");

    const balances = async (itemId, branchId, balanceDate, { holdMemberId, stockKeeper, stockType }) => {
        let urlCustom = `/${apiDefault.url}/${itemId}/${branchId}/${balanceDate}`;
        const params = [];

        if (holdMemberId) {
            params.push(`hold_member_id=${holdMemberId}`);
        }
        if (stockKeeper) {
            params.push(`stock_keeper=${stockKeeper}`);
        }
        if (stockType) {
            params.push(`type_stock=${stockType}`);
        }

        if (params.length) {
            urlCustom += `?${params.join("&")}`;
        }

        return await apiDefault.api
            .get(urlCustom)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const list = async ({ page = 1, pageSize = 5, condition }) => {
        let urlCustom = `/${apiDefault.url}/list?page=${page}&limit=${pageSize}`;

        for (let field in condition) {
            if (condition[field] !== "") {
                urlCustom += `&${field}=${condition[field]}`;
            }
        }

        return await apiDefault.api
            .get(urlCustom)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const refresh = async (branchId) => {
        return await apiDefault.api
            .post(`/${apiDefault.url}/refresh/${branchId}`)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                    info: response.data.info,
                    message: response.data.message || [],
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const listExport = async ({ layout, format, fileName, condition }) => {
        let urlCustom = `/${apiDefault.url}/export/${layout}`;

        const params = [];
        for (let field in condition) {
            if (condition[field] !== "") {
                params.push(`${field}=${condition[field]}`);
            }
        }

        if (params.length) {
            urlCustom += `?${params.join("&")}`;
        }

        return await apiDefault.api
            .get(urlCustom, { responseType: "blob" })
            .then((response) => {
                if (response.status !== 200) {
                    return {
                        status: false,
                        message: "Nenhum dado encontrado para exportação do arquivo.",
                    };
                }

                fileDownload(response.data, `${fileName}.${format}`);

                return {
                    status: response.status === 200,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    return {
        balances,
        list,
        listExport,
        refresh,
    };
}
