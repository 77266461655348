import ApiDefault from "../../ApiDefault";

export default function ApiMovementsItems() {
    const apiDefault = ApiDefault("v2/stocks/movements-items");

    const listDetails = async (condition) => {
        let urlCustom = `/${apiDefault.url}/details`;
        let urlConditions = [];

        for (const field in condition) {
            if (condition[field] !== "") {
                urlConditions.push(`${field}=${condition[field]}`);
            }
        }

        if (urlConditions.length) {
            urlCustom += `?${urlConditions.join("&")}`;
        }

        return await apiDefault.api
            .get(urlCustom)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const listPending = async (branchId) => {
        return await apiDefault.api
            .get(`/${apiDefault.url}/pending/${branchId}`)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    const cancelMovement = async (id, movementDate) => {
        return await apiDefault.api
            .delete(`/${apiDefault.url}/${id}/${encodeURIComponent(movementDate)}`)
            .then((response) => {
                return {
                    status: response.status === 200,
                    data: response.data.data,
                };
            })
            .catch((error) => {
                return {
                    status: false,
                    message: error.response.data.message,
                };
            });
    };

    return {
        apiDefault,
        cancelMovement,
        listDetails,
        listPending,
    };
}
