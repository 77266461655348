import React, { useMemo, useState } from "react";

import { Grade, GradeOpcoes } from "../../../../components";
import { Grid, Button } from "@material-ui/core";

import FormatsUtils from "../../../../services/FormatsUtils";
import InputLookupMembers from "../../../basics/lookup/members";
import InputDropDowList from "../../../../components/inputs/input.dropdowlist2";

const initialValues = {
    member_id: 0,
    member: {},
    relation_type: "",
};

export default function OutboundOrdersMembersChange({ members, setMembers, types }) {
    const [member, setMember] = useState(initialValues);

    const metaData = useMemo(() => [
        {
            label: "Opções",
            render: (record, index) => {
                const options = [
                    { tipo: "edit", label: "Edição" },
                    { tipo: "remove", label: "Exclusão" },
                ];

                return (
                    <span>
                        <GradeOpcoes record={record} indexRecord={index} onClickOpcoes={onClickOptions} opcoes={options} />
                    </span>
                );
            },
        },
        {
            label: "Tipo Membro",
            render: (record) => {
                return <>{relationMemberGroups[record.relation_type]}</>;
            },
        },
        { label: "Cód.Estab", field: "member_id", align: "right" },
        {
            label: "Membro",
            render: (record) => {
                return <>{record.member?.name}</>;
            },
        },
        {
            label: "Documento",
            render: (record) => {
                return <>{FormatsUtils().document(record.member?.document, record.member?.type_member)}</>;
            },
        },
        { label: "Dh.Criação", field: "created_at", format: "datetime", minWidth: 160 },
        { label: "Dh.Atualização", field: "updated_at", format: "datetime", minWidth: 160 },
    ]);

    const relationMemberOptions = useMemo(() => {
        return (
            types
                .find((rec) => rec.entity === "outbound_orders_members" && rec.field === "relation_type")
                ?.values?.map((rec) => {
                    return {
                        value: rec.value,
                        text: `${rec.name}`,
                    };
                }) || []
        );
    }, [types]);

    const relationMemberGroups = useMemo(() => {
        const typesCustom =
            types
                .find((rec) => rec.entity === "outbound_orders_members" && rec.field === "relation_type")
                ?.values?.map((rec) => {
                    return {
                        value: rec.value,
                        text: `${rec.name}`,
                    };
                }) || [];

        const result = {};

        if (typesCustom?.length) {
            typesCustom.forEach((rec) => {
                result[rec.value] = rec.text;
            });
        }

        return result;
    }, [types]);

    const onClickOptions = async (record, type, index) => {
        switch (type) {
            case "edit":
                setMember({ ...record });
                break;
            case "remove":
                const membersCustom = [...members];
                membersCustom.splice(index, 1);

                setMembers(membersCustom);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <InputDropDowList
                            value={member.relation_type}
                            onChangeValue={(e) => setMember({ ...member, relation_type: e.target.value })}
                            data={relationMemberOptions}
                            label={"Tipo de Membro"}
                        ></InputDropDowList>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLookupMembers
                            roleMember="supplier,customer"
                            label="Membro"
                            required
                            value={member.member}
                            onChangeValue={(data) => setMember({ ...member, member: data })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                    <Grid item xs={12} sm={8}>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px" }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => setMember(initialValues)}
                        >
                            Novo membro
                        </Button>
                        <Button
                            style={{ float: "left", marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const membersCustomIndex = members.findIndex((rec) => rec.relation_type === member.relation_type);
                                const membersCustom = [...members];

                                member.member_id = member.member.id;

                                if (membersCustomIndex === -1) {
                                    membersCustom.push(member);
                                } else {
                                    membersCustom[membersCustomIndex] = member;
                                }

                                setMembers(membersCustom);
                                setMember(initialValues);
                            }}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grade dataSource={members} metaData={metaData} disablepagination={true} />
            </Grid>
        </>
    );
}
