export const TypeStocks = [
    {
        text: "Próprio",
        value: "owner",
    },
    {
        text: "Propriedade Terceiros",
        value: "owner_third",
    },
    {
        text: "Posse Terceiros",
        value: "third_owner",
    },
];

export const TypeStocksFilter = [
    {
        text: "Todos",
        value: "-",
    },
    ...TypeStocks,
];

export const TYPE_STOCK_HOLDER = ["owner_third", "third_owner"];

export default function BalancesItemsUtils() {
    const typeStockText = (value) => {
        return TypeStocks.find((rec) => rec.value === value)?.text || null;
    };

    return {
        typeStockText,
    };
}
